import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data; // Updated to reflect new backend data structure
  const [internet, setInternet] = useState(0);
  const [video, setVideo] = useState(0);
  const [voice, setVoice] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (Agent && Agent.PartnerID && Data) {
      updatePendingSales(Data);
    }
  }, [Data, Agent]);

  const updatePendingSales = (data) => {
    setInternet(data.InternetPending || 0);
    setVideo(data.VideoPending || 0);
    setVoice(data.VoicePending || 0);
    setMobile(data.MobilePending || 0);

    const totalPending =
      (data.InternetPending || 0) +
      (data.VideoPending || 0) +
      (data.VoicePending || 0) +
      (data.MobilePending || 0);

    setTotal(totalPending);
  };

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Pending sales
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Internet: {internet}</Box>
        <Box>Video: {video}</Box>
        <Box>Voice: {voice}</Box>
        <Box>Mobile: {mobile}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          color={colors.lightHeaders[100]}
          variant="h1"
          fontWeight="600"
        >
          {total}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
