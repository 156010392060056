import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../../theme";

const BarChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { agent } = props;
  const [barData, setBarData] = useState([
    {
      month: "October",
      Internet: 0,
      Video: 0,
      Voice: 0,
      Mobile: 0,
    },
  ]);

  useEffect(() => {
    if (agent && agent.AgentID) {
      fetchData(agent.AgentID);
    }
  }, [agent]);

  const fetchData = async (agentID) => {
    const payload = { AgentID: agentID };
    const url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeChargebackAndOrdersAggregated";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      processData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const processData = (data) => {
    const formattedData = [
      {
        month: "October",
        Internet: data.InternetChargebacks || 0,
        Video: data.VideoChargebacks || 0,
        Voice: data.VoiceChargebacks || 0,
        Mobile: data.MobileChargebacks || 0,
      },
      {
        month: "November",
        Internet: data.InternetSalesCanceled || 0,
        Video: data.VideoSalesCanceled || 0,
        Voice: data.VoiceSalesCanceled || 0,
        Mobile: data.MobileSalesCanceled || 0,
      },
    ];

    setBarData(formattedData);
  };

  return (
    <ResponsiveBar
      data={barData}
      theme={{
        axis: {
          domain: { line: { stroke: colors.grey[100] } },
          legend: { text: { fill: colors.grey[100] } },
          ticks: {
            line: { stroke: colors.grey[100], strokeWidth: 1 },
            text: { fill: colors.grey[100] },
          },
        },
        legends: { text: { fill: colors.grey[100] } },
      }}
      keys={["Internet", "Video", "Voice", "Mobile"]}
      indexBy="month"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      borderColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Month",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={0}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [{ on: "hover", style: { itemOpacity: 1 } }],
        },
      ]}
      role="application"
      barAriaLabel={({ id, value, indexValue }) =>
        `${id}: ${value} in ${indexValue}`
      }
    />
  );
};

export default BarChart;
