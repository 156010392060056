import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const CancellationTile = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { agent, data } = props;

  const [cancellations, setCancellations] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (agent && data) {
      calculateCancellationPercentage(data);
    }
  }, [data, agent]);

  const calculateCancellationPercentage = (data) => {
    // Sum cancellations across all product types
    const totalCancellations =
      (data.InternetSalesCanceled || 0) +
      (data.VideoSalesCanceled || 0) +
      (data.VoiceSalesCanceled || 0) +
      (data.MobileSalesCanceled || 0);

    const totalEntries = data.CBPEntryCount || 0; // Total entries in chargeback_pending

    setCancellations(totalCancellations);
    setTotalEntries(totalEntries);

    // Calculate percentage, handle division by zero gracefully
    const calculatedPercentage = totalEntries
      ? ((totalCancellations / totalEntries) * 100).toFixed(2)
      : 0;

    setPercentage(calculatedPercentage);

    // Change background color based on percentage
    setColorScale(calculatedPercentage > 10 ? "#e22839" : "#4cceac");
  };

  return (
    <Box
      gridColumn="span 1"
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        backgroundColor={colorScale}
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridColumn="span 2"
        textAlign="center"
        borderRadius="25px"
        color="white"
      >
        <Typography variant="h5" fontWeight="600">
          Cancellations: {percentage || 0}%
        </Typography>
      </Box>
    </Box>
  );
};

export default CancellationTile;
