import React, { useEffect } from "react";
import Iframe from "react-iframe";
import { useLocation } from "react-router-dom";

const PartnerRegisterSale = (props) => {
  const location = useLocation();
  let Values;
  let AgentID;
  let Url;
  // console.log("L O U D : ", location.state.values);

  if (props.agent) {
    AgentID = props.agent.AgentID;
  }

  if (location.state) {
    console.log("L O U D : ", location.state);

    Values = location.state.values;
    if (Values.campaign) {
      Url = `https://fs10.formsite.com/yU3au7/0tbguuadyj/fill?id0=${Values.partnerID}`;
    } else {
      Url = `https://fs10.formsite.com/yU3au7/9v1qdwlvqg/fill?id0=${AgentID}&id6=${Values.address1}&id7=${Values.address2}&id10=${Values.zipCode}&id94=${Values.moving}&id95=${Values.prevaddress1}&id96=${Values.prevaddress2}&id97=${Values.zipCode}&id4=${Values.firstName}&id5=${Values.lastName}&id98=${Values.mobile}&id22=${Values.email}`;
    }
  } else {
    Url = `https://fs10.formsite.com/yU3au7/9v1qdwlvqg/fill?id0=${AgentID}`;
  }

  // console.log("L O U D : ", location.state.values);

  useEffect(() => {
    setTimeout(function () {
      // do something after 5 seconds
      alert("Make sure to register your sale!");
    }, 5000);
  }, []);

  return (
    <div>
      <Iframe
        url={Url}
        width="100%"
        height="850px"
        id="formFrame"
        className="formFrame"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default PartnerRegisterSale;
