import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;
  const [internet, setInternet] = useState(0);
  const [video, setVideo] = useState(0);
  const [voice, setVoice] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [total, setTotal] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (
      Agent &&
      Agent.PartnerID &&
      Data &&
      String(Data.Agent).toLowerCase() === String(Agent.AgentID).toLowerCase()
    ) {
      setInternet(Data.InternetSalesCanceled || 0);
      setVideo(Data.VideoSalesCanceled || 0);
      setVoice(Data.VoiceSalesCanceled || 0);
      setMobile(Data.MobileSalesCanceled || 0);
      const totalCanceled =
        (Data.InternetSalesCanceled || 0) +
        (Data.VideoSalesCanceled || 0) +
        (Data.VoiceSalesCanceled || 0) +
        (Data.MobileSalesCanceled || 0);
      setTotal(totalCanceled);

      // Set color scale based on total canceled percentage
      const totalPercentage = (totalCanceled / (Data.TotalSales || 1)) * 100;
      if (totalPercentage > 10) {
        setColorScale("#FF3D3D");
      } else {
        setColorScale("#4cceac");
      }
    }
  }, [Data, Agent]);

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Orders canceled
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Internet: {internet}</Box>
        <Box>Video: {video}</Box>
        <Box>Voice: {voice}</Box>
        <Box>Mobile: {mobile}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={colorScale} variant="h1" fontWeight="600">
          {total}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
