import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { realData } from "../../data/realData";
import { Link } from "react-router-dom";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [cBData, setCBData] = useState([]);
  const [agentObjects, setAgentObjects] = useState([]);
  const [agentIDs, setAgentIDs] = useState([]);
  // const [period, setPeriod] = useState("");
  let Agent = props.agent;
  let Period = props.period;

  // let agentIDs = [];
  // let agentObjects = [];

  const singleAgent = [
    {
      FirstName: "Jo1hn",
      lastName: "Doe",
      age: 50,
      eyeColor: "blue",
      AgentID: "agty2omomma",
    },
    {
      FirstName: "Jo2hn",
      lastName: "Doe",
      age: 50,
      eyeColor: "blue",
      AgentID: "agtyo5momma",
    },
    {
      FirstName: "Jo4hn",
      lastName: "Doe",
      age: 50,
      eyeColor: "blue",
      AgentID: "agty1omomma",
    },
  ];

  useEffect(() => {
    if (Agent && Agent.PartnerID && data.length == 0) {
      // setPeriod(props.period);
      console.log("test team period: ", Period);
      fetchAgentVTLs();
    }
  }, [Period, Agent]);

  // Fetch all agents under the same VTL
  const fetchAgentVTLs = async () => {
    let url = "";
    let AgentIDs = [];
    let AgentObjects = [];

    // let VTL = { VTL: "VTL1295" };
    let VTL = { VTL: Agent.PartnerID };
    const VTLfk = { VTL: "VTL1295" };

    url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getAgentsByVTL";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(VTL),
    })
      .then((response) => response.json())
      .then((Data) => {
        // Do something with the data, e.g. update component state
        setData([]);
        setData(Data);

        // Create Agent Objects for each agent
        Data.forEach((item) => {
          AgentIDs.push(item.AgentID);

          AgentObjects.push({
            AgentID: item.AgentID,
            FirstName: item.FirstName,
            Internet: 0,
            Video: 0,
            Mobile: 0,
            Voice: 0,
            Chargebacks: 0,
            TotalUnits: 0,
            Canceled: 0,
            All: 0,
          });
        });
        fetchChargebacksByMultipleVTLs(AgentIDs, AgentObjects);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // Fetch all agents under the same VTL
  const fetchChargebacksByMultipleVTLs = async (AgentIDs, AgentObjects) => {
    let url = "";
    let Payload = { AgentIDs: AgentIDs };

    url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getChargebacksByMultipleVTLs";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Payload),
    })
      .then((response) => response.json())
      .then((Data) => {
        // Do something with the data, e.g. update component state
        setCBData(Data);
        console.log("TEAM DATA: ", Data);
        // Iterate through response and build agent data
        Data.forEach((item) => {
          // AgentIDs.push(item.AgentID);
          let found = AgentObjects.findIndex(
            (element) =>
              element.AgentID.toUpperCase() == item.Agent.toUpperCase()
          );
          // Data Churning Machine
          if (found != "undefined") {
            let fiscalCaps = item.FiscalMonth.toUpperCase();
            let periodCaps = Period.toUpperCase();
            // console.log("test Fiscal: ", fiscalCaps);
            // console.log("test Period: ", periodCaps);

            if (
              Period == "all" ||
              (fiscalCaps.search(periodCaps) >= 0 && Period != "")
            ) {
              // Get all Sales Connected
              if (item.SalesConnects == 1) {
                AgentObjects[found].All++;
              }

              // Get chargebacks
              if (item.Disconnects == 1 && item.Survival90Day == 0) {
                AgentObjects[found].Chargebacks++;
              }

              // Get canceled
              if (item.SalesCanceled == 1) {
                AgentObjects[found].Canceled++;
              }

              // Get respective sales
              if (item.PSU == "Internet") {
                AgentObjects[found].Internet++;
              }
              if (item.PSU == "Video") {
                AgentObjects[found].Video++;
              }
              if (item.PSU == "Voice") {
                AgentObjects[found].Voice++;
              }
              if (item.PSU == "Mobile") {
                AgentObjects[found].Mobile++;
              }
            }
          }
        });
        setAgentObjects(AgentObjects);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const columns = [
    {
      field: "FirstName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "All",
      headerName: "Total",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Chargebacks",
      headerName: "CBs",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Canceled",
      headerName: "Cncld",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Internet",
      headerName: "Int",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Video",
      headerName: "Vid",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Mobile",
      headerName: "Mob",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Voice",
      headerName: "Voc",
      flex: 1,
      cellClassName: "name-column--cell",
    },
  ];

  return (
    <Box
      key={Period}
      m="0px 0 0px 0"
      height="100%"
      width="auto"
      sx={{
        "& .MuiTablePagination-root": {
          color: colors.lightHeaders[100],
        },
        "& .MuiDataGrid-root": {
          border: "none",
          color: colors.lightHeaders[100],
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.lightHeaders[100],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
          color: colors.lightHeaders[100],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
      }}
    >
      <DataGrid
        rows={agentObjects}
        columns={columns}
        getRowId={(row) => row.AgentID}
      />
    </Box>
  );
};

export default Team;
