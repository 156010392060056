import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const ChargebackTile = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;

  const [percentage, setPercentage] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (Agent && Data) {
      calculateChargebackPercentage(Data);
    }
  }, [Data, Agent]);

  const calculateChargebackPercentage = (data) => {
    const totalSales = data.CBPEntryCount || 0; // Total entries from chargeback_pending
    const validChargebacks = data.Chargebacks || 0; // Valid chargebacks meeting criteria

    const calculatedPercentage = totalSales
      ? ((validChargebacks / totalSales) * 100).toFixed(2)
      : 0;

    setPercentage(calculatedPercentage);

    // Update background color based on percentage
    setColorScale(calculatedPercentage > 15 ? "#e22839" : "#4cceac");
  };

  return (
    <Box
      gridColumn="span 1"
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        backgroundColor={colorScale}
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridColumn="span 2"
        textAlign="center"
        borderRadius="25px"
        color="white"
      >
        <Typography variant="h5" fontWeight="600">
          Chargebacks: {percentage || 0}%
        </Typography>
      </Box>
    </Box>
  );
};

export default ChargebackTile;
