import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const PieChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data;

  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (Agent && Data) {
      dataChurningMachine(Data);
    }
  }, [Data]);

  const dataChurningMachine = (apiResponse) => {
    const newPieData = [
      {
        id: "Essentials",
        label: "Essentials",
        value: apiResponse.Essentials || 0,
        color: "hsl(104, 70%, 50%)",
      },
      {
        id: "MiPlanPlus",
        label: "Mi Plan Plus",
        value: apiResponse.MiPlanPlus || 0,
        color: "hsl(162, 70%, 50%)",
      },
      {
        id: "Select",
        label: "Select",
        value: apiResponse.SelectTier || 0,
        color: "hsl(291, 70%, 50%)",
      },
      {
        id: "SelectPlus",
        label: "Select Plus",
        value: apiResponse.SelectPlus || 0,
        color: "hsl(229, 70%, 50%)",
      },
      {
        id: "SelectSignature",
        label: "Select Signature",
        value: apiResponse.SelectSignature || 0,
        color: "hsl(229, 70%, 50%)",
      },
    ];

    setPieData(newPieData);
  };

  return (
    <ResponsivePie
      data={pieData}
      margin={{ top: 10, right: -30, bottom: 10, left: 0 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={1}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={1}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
      legends={[
        {
          anchor: "left",
          direction: "column",
          justify: false,
          translateX: 0,
          translateY: 0,
          itemWidth: 0,
          itemHeight: 20,
          itemsSpacing: 0,
          symbolSize: 20,
          itemDirection: "left-to-right",
          itemTextColor: "#ebeef3",
        },
      ]}
    />
  );
};

export default PieChart;
