import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const InternetGigTile = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { agent, data } = props;

  const [internetGigSales, setInternetGigSales] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (agent && data) {
      calculateInternetGigPercentage(data);
    }
  }, [data, agent]);

  const calculateInternetGigPercentage = (data) => {
    const total = data.CBPEntryCount || 0; // From the API response
    const internetGig = data.InternetGigSales || 0; // Sales with Internet PSU + Gig

    setTotalSales(total);
    setInternetGigSales(internetGig);

    const calculatedPercentage = total
      ? ((internetGig / total) * 100).toFixed(2)
      : 0;

    setPercentage(calculatedPercentage);

    // Set background color based on percentage
    setColorScale(calculatedPercentage < 18 ? "#e22839" : "#4cceac");
  };

  return (
    <Box
      gridColumn="span 1"
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        backgroundColor={colorScale}
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridColumn="span 2"
        textAlign="center"
        borderRadius="25px"
        color="white"
      >
        <Typography variant="h5" fontWeight="600">
          Internet Gig: {percentage || 0}%
        </Typography>
      </Box>
    </Box>
  );
};

export default InternetGigTile;
