import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { realData } from "../data/realData";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Admin = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [selected, setSelected] = useState("");
  const [hierarchy, setHierarchy] = useState("");
  const [ipLock, setIpLock] = useState("");
  const [statusDrop, setStatusDrop] = useState("");

  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);

  let Agent = props.agent;
  const excludedIds = ["Pending", "Unknown", null];

  useEffect(() => {
    if (Agent && Agent.PartnerID && data.length == 0) {
      console.log("if statement", Agent.PartnerID);
      fetchDataFromDatabase();
    }
    const selectedAgent = data.find((agent) => agent.AgentID === selected);
    if (selectedAgent) {
      setHierarchy(selectedAgent.Hierarchy || ""); // Ensure valid defaults
      setIpLock(
        selectedAgent.IPAddress !== "Unclaimed" &&
          selectedAgent.IPAddress !== "Unlocked"
          ? "Locked"
          : selectedAgent.IPAddress
      );
      setStatusDrop(selectedAgent.Status || "");
    }
  }, [Agent, selected, data]); // Only trigger on selection change

  const fetchDataFromDatabase = async () => {
    let url = "";
    console.log("fetchDataFromDatabase: ", Agent.PartnerID);
    let VTL = { VTL: Agent.PartnerID };
    console.log("H: ", Agent.Hierarchy);
    if (Agent.Hierarchy == "Master") {
      url = "https://us-central1-argon-fx-383518.cloudfunctions.net/queryDB";
    } else if (Agent.Hierarchy == "Owner" || "Sub Distributor" || "Manager") {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getAgentsByVTL";
    }

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(VTL),
    })
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setData([]);
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // toggle status in database based on AgentID
  const toggleAgent = async (AgentID, Status) => {
    let payload = { AgentID: AgentID, Status: Status };
    console.log(payload);

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/toggleAgent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Parse the response as JSON
      const data = await response.json();

      // Use the message from the parsed data
      notify(data.message);
      console.log("Response: ", data);

      // Refresh or update the component state
      fetchDataFromDatabase();
    } catch (error) {
      // Handle error
      notifyError("Error: " + error.message + " [LE004]");
    }
  };

  // toggle status in database based on AgentID
  const toggleSelectedAgent = async (AgentID) => {
    setSelected(AgentID);
    notify(`Selected: ${AgentID}`);

    // try {
    //   const response = await fetch(
    //     "https://us-central1-argon-fx-383518.cloudfunctions.net/toggleAgent",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify(payload),
    //     }
    //   );

    //   // Check if the response is okay
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }

    //   // Parse the response as JSON
    //   const data = await response.json();

    //   // Use the message from the parsed data
    //   notify(data.message);
    //   console.log("Response: ", data);

    //   // Refresh or update the component state
    //   fetchDataFromDatabase();
    // } catch (error) {
    //   // Handle error
    //   notifyError("Error: " + error.message + " [LE004]");
    // }
  };

  const handleSubmit = async () => {
    let payload = {};
    if (!selected) {
      notifyError("No agent selected.");
      return;
    }

    if (ipLock !== "Locked") {
      payload = {
        AgentID: selected,
        Hierarchy: hierarchy,
        IPAddress: ipLock,
        Status: statusDrop,
      };
    } else {
      payload = {
        AgentID: selected,
        Hierarchy: hierarchy,
        Status: statusDrop,
      };
    }

    try {
      console.log("Payload: ", payload);
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/adminUpdateAgentDetails", // Replace with actual endpoint
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update agent details.");
      }

      setData((prevData) =>
        prevData.map((agent) =>
          agent.AgentID === selected
            ? {
                ...agent,
                Hierarchy: hierarchy,
                IPAddress:
                  ipLock === "Locked"
                    ? agent.IPAddress // Keep the previous value if "Locked" is selected
                    : ipLock, // Update to the new value for other cases
                Status: statusDrop,
              }
            : agent
        )
      );

      const result = await response.json();
      console.log("Response:", result);
      notify("Agent details updated successfully!");
    } catch (error) {
      console.error("Error:", error);
      notifyError("Failed to update agent details.");
    }
  };

  const columns = [
    {
      field: "PartnerID",
      headerName: "Partner ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "AgentID", headerName: "Agent ID" },
    {
      field: "FirstName",
      headerName: "first Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "LastName",
      headerName: "last Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "MobileNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { Status, AgentID } }) => {
        let jsxBlock = null;
        if (Agent.Hierarchy === "Master" || Agent.Hierarchy === "Owner") {
          jsxBlock = (
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              onClick={() => {
                toggleAgent(AgentID, Status);
              }}
              backgroundColor={
                Status === "Active"
                  ? colors.greenAccent[600]
                  : Status === "Disabled"
                  ? colors.redAccent[600]
                  : colors.greenAccent[700]
                  ? "#FF9800" // Use yellowAccent for Limited status
                  : colors.greenAccent[700]
              }
              borderRadius="4px"
            >
              {Status === "Active" && <FlashOnIcon />}
              {Status === "Disabled" && <LockPersonIcon />}
              {Status === "Limited" && <WarningAmberIcon />}
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                {Status}
              </Typography>
            </Box>
          );
        }
        return jsxBlock;
      },
    },
    {
      headerName: "Select",
      flex: 1,
      renderCell: ({ row: { AgentID } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            onClick={() => {
              toggleSelectedAgent(AgentID);
            }}
            backgroundColor={
              selected === AgentID ? colors.primary[800] : colors.primary[600]
            }
            borderRadius="4px"
          >
            {selected === AgentID && <CheckCircleOutlineIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {selected === AgentID ? "Selected" : "Select"}
            </Typography>
          </Box>
        );
        return jsxBlock;
      },
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header
          sx={{ color: colors.blueAccent[800] }}
          title="Admin UI"
          subtitle="Admin level Agent management"
        />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            component={Link}
            to="/agentRegistration"
          >
            <PersonAddAltIcon sx={{ mr: "10px" }} />
            Add Agent
          </Button>
        </Box>
      </Box>

      {/* AGENT UPDATER SECTION */}

      <Box
        alignItems="center"
        justifyContent="center"
        display="grid"
        gridTemplateColumns="repeat(6, 1fr)"
        gap="0px"
        overflow="hidden"
        color={"white"}
        height={"100px"}
      >
        <Box
          backgroundColor={colors.primary[400]}
          display="flex"
          flexDirection="row"
          gridColumn={"span 6"}
          alignItems="center"
          justifyContent="space-between"
          gap="20px"
          overflow="hidden"
          color={"white"}
          padding="20px"
          borderRadius="8px"
        >
          {selected ? (
            <>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                <strong>Editing :</strong> {selected}
              </Typography>

              {/* Hierarchy Dropdown */}
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 200,
                  margin: "0 10px",
                  backgroundColor: "#E3F2FD", // Light blue background for dropdown
                  borderRadius: "8px",
                }}
              >
                <InputLabel
                  shrink
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    top: "-5px",
                  }}
                >
                  Hierarchy
                </InputLabel>
                <Select
                  value={hierarchy || ""}
                  onChange={(e) => setHierarchy(e.target.value)}
                  label="Hierarchy"
                  sx={{
                    "& .MuiSelect-outlined": {
                      padding: "10px",
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Hierarchy
                  </MenuItem>
                  <MenuItem value="Master">Master</MenuItem>
                  <MenuItem value="Owner">Owner</MenuItem>
                  <MenuItem value="Sub Distributor">Sub Distributor</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                </Select>
              </FormControl>

              {/* IP Lock Dropdown */}
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 200,
                  margin: "0 10px",
                  backgroundColor: "#E3F2FD", // Light blue background for dropdown
                  borderRadius: "8px",
                }}
              >
                <InputLabel
                  shrink
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    top: "-5px",
                  }}
                >
                  IP Lock
                </InputLabel>
                <Select
                  value={ipLock || ""}
                  onChange={(e) => setIpLock(e.target.value)}
                  label="IP Lock"
                  sx={{
                    "& .MuiSelect-outlined": {
                      padding: "10px",
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select IP Lock
                  </MenuItem>
                  <MenuItem value="Unclaimed">Unclaimed</MenuItem>
                  <MenuItem value="Unlocked">Unlocked</MenuItem>
                  <MenuItem value="Locked">Locked</MenuItem>
                </Select>
              </FormControl>

              {/* Status Dropdown */}
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 200,
                  margin: "0 10px",
                  backgroundColor: "#E3F2FD", // Light blue background for dropdown
                  borderRadius: "8px",
                }}
              >
                <InputLabel
                  shrink
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    top: "-5px",
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  value={statusDrop || ""}
                  onChange={(e) => setStatusDrop(e.target.value)}
                  label="Status"
                  sx={{
                    "& .MuiSelect-outlined": {
                      padding: "10px",
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Disabled">Disabled</MenuItem>
                  <MenuItem value="Limited">Limited</MenuItem>
                </Select>
              </FormControl>

              {/* Submit Button */}
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  color: "white",
                  minWidth: 150,
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px",
                  backgroundColor: "#1976D2", // Darker blue
                  "&:hover": {
                    backgroundColor: "#1565C0", // Slightly darker on hover
                  },
                }}
              >
                Submit Changes
              </Button>
              {/* Delete Button */}
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  minWidth: 150,
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px",
                  backgroundColor: colors.redAccent[600], // Darker blue
                  "&:hover": {
                    backgroundColor: colors.redAccent[800], // Slightly darker on hover
                  },
                }}
              >
                Delete
              </Button>
            </>
          ) : (
            <Typography
              sx={{
                color: "white",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              No Agent selected: Please select an agent from the table.
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        m="10px 0 40px 0"
        height="75vh"
        sx={{
          "& .MuiTablePagination-root": {
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-root": {
            border: "none",
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.lightHeaders[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data.filter((row) => !excludedIds.includes(row.AgentID))}
          columns={columns}
          getRowId={(row) => row.AgentID}
        />
      </Box>
    </Box>
  );
};

export default Admin;
