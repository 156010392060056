import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";

const Profile = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [profileData, setProfileData] = useState(data);

  useEffect(() => {
    setProfileData(data);
  }, [data]);

  useEffect(() => {
    if (profileData && profileData.AgentID) {
      console.log("Data Prof", profileData);
    }
  }, [profileData]);

  return (
    <Box
      m="10px 0 40px 0"
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      rowGap="15px"
      columnGap="15px"
      justifyContent={"center"}
    >
      {/* Training Status */}
      <Box
        sx={{ gridColumn: "span 12", boxShadow: 3 }}
        backgroundColor="#f2f0f0"
        padding="15px"
        textAlign="center"
      >
        <Typography
          color={colors.grey[900]}
          variant="h5"
          fontWeight="600"
          marginBottom={"10px"}
        >
          Training Status
        </Typography>
        <Box
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          flexWrap="wrap"
          gap="10px"
        >
          {[
            { label: "Mobile", value: profileData.MobileTraining },
            { label: "Internet Spect", value: profileData.InternetSpect },
            { label: "Portal Training", value: profileData.PortalTraining },
            { label: "Marketing", value: profileData.MarketingTraining },
            { label: "Sales 101", value: profileData.Sales101 },
            { label: "Call Flow", value: profileData.CallFlow },
            { label: "Role Play", value: profileData.RolePlay },
          ].map((training) => (
            <Box
              key={training.label}
              sx={{
                width: 130,
                height: 80,
                boxShadow: 3,
                backgroundColor:
                  training.value === "Yes"
                    ? colors.greenAccent[400]
                    : colors.redAccent[500],
                padding: "10px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                color={colors.grey[100]}
                variant="h6"
                fontWeight="bold"
              >
                {training.label}
              </Typography>
              <Typography color={colors.grey[100]} variant="h6">
                {training.value === "Yes" ? "Complete" : "Incomplete"}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Primary owner information */}
      <Box
        sx={{ gridColumn: "span 4", boxShadow: 3 }}
        backgroundColor="#f2f0f0"
        padding="15px"
      >
        <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
          Agent Information
        </Typography>
        <Typography
          color={colors.blueAccent[500]}
          variant="h6"
          fontWeight="600"
        >
          {profileData.AgentName}
        </Typography>
        <Typography
          color={colors.blueAccent[500]}
          variant="h6"
          fontWeight="600"
        >
          {profileData.Email}
        </Typography>
        <Typography
          color={colors.blueAccent[500]}
          variant="h6"
          fontWeight="600"
        >
          {profileData.MobileNumber}
        </Typography>
      </Box>

      {/* ID URLs */}
      <Box
        sx={{
          gridColumn: "span 4",
          boxShadow: 3,
          backgroundColor: "#f2f0f0",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "300px", // Set a fixed height for the boxes
        }}
      >
        <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
          Copy of ID
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <img
            src={profileData.IDUrl}
            alt="ID"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          gridColumn: "span 4",
          boxShadow: 3,
          backgroundColor: "#f2f0f0",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "300px", // Set a fixed height for the boxes
        }}
      >
        <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
          Copy of ID Verification
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <img
            src={profileData.IDVerifyUrl}
            alt="ID Verification"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
