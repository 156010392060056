import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Profile from "./TeamExpViewComps/AgentProfile";
import Sales from "./TeamExpViewComps/AgentSales";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { realData } from "../data/realData";
import Header from "../components/Header";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [period, setPeriod] = useState("all");
  const [partnerDetails, setPartnerDetails] = useState({});
  const [activeComponent, setActiveComponent] = useState("Agent");

  let User = props.agent;
  const location = useLocation();
  const AgentID = location.state?.AgentID;

  useEffect(() => {
    if (User && User.PartnerID && data.length === 0) {
      console.log("AgentID PROP ID: ", AgentID);
      FetchFromDatabase();
    }
  }, [User, AgentID]);

  // Fetch Partner Details
  const FetchFromDatabase = async () => {
    let Payload = { AgentID: AgentID };
    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/teamExpandedView";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const Data = await response.json();

      if (!Data || Data.length === 0) {
        throw new Error("No data returned from the API");
      }

      setData(Data);
      console.log("DATA: ", Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event) => {
    let food = event.target.value;
    setPeriod(food);
    console.log("test dashboard: ", period);
  };

  const getColor = (value, condition) => {
    if (condition(value)) {
      return colors.redAccent[500];
    }
    return colors.greenAccent[500];
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Box>
          <Header title={data.AgentName} subtitle={data.AgentID} />
        </Box>

        <Box>
          <Typography color={colors.grey[900]} variant="h4" fontWeight="600">
            Status
          </Typography>
          <Typography color="secondary" variant="h5" fontWeight="600">
            {data.Status}
          </Typography>
        </Box>
      </Box>

      <Box
        m="10px 0 0px 0"
        display="grid"
        gridTemplateColumns="repeat(5, 1fr)"
        rowGap="15px"
        padding="15px"
        justifyContent={"center"}
        backgroundColor="#f2f0f0"
        sx={{ boxShadow: 3 }}
        textAlign="center"
      >
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            Units
          </Typography>
          <Typography
            color={getColor(data.UnitPercentage, (val) => val < 0)}
            variant="h5"
            fontWeight="600"
          >
            {data.UnitPercentage + " %"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            S. Chargeback
          </Typography>
          <Typography
            color={getColor(data.ChargebackPercentage, (val) => val > 15)}
            variant="h5"
            fontWeight="600"
          >
            {data.ChargebackPercentage + " %"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            Chargeback
          </Typography>
          <Typography
            color={getColor(data.ChargebackPercentage, (val) => val > 15)}
            variant="h5"
            fontWeight="600"
          >
            {data.ChargebackPercentage + " %"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            Cancelled
          </Typography>
          <Typography
            color={getColor(data.SalesCanceledPercentage, (val) => val > 15)}
            variant="h5"
            fontWeight="600"
          >
            {data.SalesCanceledPercentage + " %"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            Mobile
          </Typography>
          <Typography
            color={getColor(data.VoicePercentage, (val) => val < 20)}
            variant="h5"
            fontWeight="600"
          >
            {data.VoicePercentage + " %"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            Gig
          </Typography>
          <Typography
            color={getColor(data.GigPercentage, (val) => val < 15)}
            variant="h5"
            fontWeight="600"
          >
            {data.GigPercentage + " %"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            Video
          </Typography>
          <Typography
            color={getColor(data.VideoPercentage, (val) => val > 0)}
            variant="h5"
            fontWeight="600"
          >
            {data.VideoPercentage + " %"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.grey[900]} variant="h5" fontWeight="600">
            Training
          </Typography>
          <Typography
            color={getColor(
              data.TrainingComplete,
              (val) => val === "No" || val === "Incomplete"
            )}
            variant="h5"
            fontWeight="600"
          >
            {data.TrainingComplete === "Yes" ? "Complete" : "Incomplete"}
          </Typography>
        </Box>
      </Box>

      <Box
        m="10px 0 0px 0"
        display="grid"
        gridTemplateColumns="repeat(5, 1fr)"
        rowGap="15px"
        columnGap="15px"
        padding="15px"
        justifyContent={"center"}
        textAlign="center"
      >
        <Button
          variant="contained"
          color={activeComponent === "Agent" ? "secondary" : "grey"}
          onClick={() => setActiveComponent("Agent")}
        >
          Agent
        </Button>
        <Button
          variant="contained"
          color={activeComponent === "Sales" ? "secondary" : "grey"}
          onClick={() => setActiveComponent("Sales")}
        >
          Sales
        </Button>
        <Button variant="contained" color="grey" disabled>
          Call Calibrations
        </Button>
      </Box>

      {activeComponent === "Agent" ? (
        <Profile data={data} />
      ) : (
        <Sales data={data} />
      )}
    </Box>
  );
};

export default Team;
