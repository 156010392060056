import React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import Header from "../components/Header";
import { tokens } from "../theme";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";

const News = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const Agent = props.agent;

  useEffect(() => {
    fetchDataFromDatabase();
  }, []);

  const fetchDataFromDatabase = async () => {
    await fetch(
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getBlogs"
    )
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const truncateTitle = (title, maxLength) =>
    title.length > maxLength ? title.substring(0, maxLength) + "..." : title;

  const stripHTMLTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  return (
    <Box
      m="20px"
      display={"grid"}
      columnGap={"0px"}
      rowGap={"15px"}
      maxWidth={"70vw"}
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Box
        sx={{ gridColumn: "span 4" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header title="News" subtitle="Vomtel & Spectrum news" />
        <Box>
          {Agent !== undefined && Agent.Hierarchy == "Master" && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              component={Link}
              to="/createPost"
            >
              <NoteAddIcon sx={{ mr: "10px" }} />
              New post
            </Button>
          )}
        </Box>
      </Box>

      <Box
        m="20px"
        gridColumn={"span 4"}
        display="grid"
        columnGap="20px"
        rowGap="20px"
        maxWidth="70vw"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      >
        {data.map((element) => (
          <Card
            sx={{ maxWidth: 345 }}
            key={element.id}
            component={Link}
            to="/showPost"
            state={{ title: element.title, body: element.body, id: element.id }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CardMedia
              sx={{ height: 140 }}
              image={
                element.img
                  ? process.env.PUBLIC_URL + element.img
                  : process.env.PUBLIC_URL + "assets/spectrum blank.jpg"
              }
              title={element.title}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color={colors.primary[500]}
                fontWeight="fontWeightBold"
              >
                {truncateTitle(element.title, 20)}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {truncateTitle(stripHTMLTags(element.body), 60)}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        sx={{ gridColumn: "span 4" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Box></Box>
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "0px 20px",
            }}
            component={Link}
            to="/newsviewall"
          >
            <VisibilityIcon sx={{ mr: "10px" }} />
            view all
          </Button>
        </Box>
      </Box>
      <Box
        sx={{ gridColumn: "span 2" }}
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
        justifySelf={"center"}
      >
        <Header justifySelf={"center"} subtitle="Products and Services" />
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/assets/pdfs/Bar Restaurant Rate Card 12.01.18.pdf"
            }
            target="_blank"
          >
            Spectrum Business Rate Card
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL + "/assets/pdfs/SPP 2.0 Rates 5.3.19.pdf"
            }
            target="_blank"
          >
            Spectrum Residential Rate Card
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/assets/pdfs/SPP 3.0 Channel Line-up.pdf"
            }
            target="_blank"
          >
            Spectrum Channel Lineup
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/assets/pdfs/International Channel Packages.pdf"
            }
            target="_blank"
          >
            Spectrum International Channel Lineup
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/assets/pdfs/International Channel Packages BuyFlow.pdf"
            }
            target="_blank"
          >
            Spectrum International Channel Lineup NEW
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/assets/pdfs/Spectrum Voice International.pdf"
            }
            target="_blank"
          >
            Spectrum International Long Distance
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/assets/pdfs/SPP 3.0 Sales - Retail Partners.pdf"
            }
            target="_blank"
          >
            Spectrum Pricing & Packages Update 3.0
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL + "/assets/pdfs/SIM+WoW+work+around.pdf"
            }
            target="_blank"
          >
            SIM+WoW+work+around
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/public/assets/pdfs/SIM+WoW+Activation+Workaround+Options.pdf"
            }
            target="_blank"
          >
            SIM+WoW+Activation+Workaround+Options
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/public/assets/pdfs/Mobile+Portal+QRG.pdf"
            }
            target="_blank"
          >
            Mobile+Portal+QRG
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/public/assets/pdfs/Why Spectrum Mobile - Competitive Advantages.pdf"
            }
            target="_blank"
          >
            Why Spectrum Mobile - Competitive Advantages
          </a>
        </p>
      </Box>
      <Box
        sx={{ gridColumn: "span 2" }}
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
        justifySelf={"center"}
      >
        <Header justifySelf={"center"} subtitle="Important Forms" />
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/public/assets/pdfs/Spectrum Contract Buyout.pdf"
            }
            target="_blank"
          >
            Spectrum Contract Buy out Form English
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL +
              "/public/assets/pdfs/Spectrum Contract Buyout Spanish.pdf"
            }
            target="_blank"
          >
            Spectrum Contract Buy out Form Spanish
          </a>
        </p>
        <p className="m-1">
          <a
            href={
              process.env.PUBLIC_URL + "/public/assets/pdfs/Sales Sheet ENG.PDF"
            }
            target="_blank"
          >
            Spectrum Sales Sheet
          </a>
        </p>
      </Box>
    </Box>
  );
};

export default News;
