import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { realData } from "../../data/realData";
import { Link } from "react-router-dom";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);

  let Agent = props.agent;

  useEffect(() => {
    console.log("useeffect", data.length);

    if (Agent && Agent.PartnerID && data.length == 0) {
      console.log("if statement", Agent.PartnerID);
      fetchDataFromDatabase();
    }
  }, [Agent]);

  const fetchDataFromDatabase = async () => {
    let url = "";
    if (Agent.Hierarchy == "Master" || Agent.Hierarchy == "Spectrum") {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getAllCanceledOrders";
    } else if (
      Agent.Hierarchy == "Manager" ||
      Agent.Hierarchy == "Owner" ||
      Agent.Hierarchy == "Sub Distributor" ||
      Agent.Hierarchy == "Retention" ||
      Agent.Hierarchy == "Quality Assurance"
    ) {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getCanceledByPartnerID";
    } else if (Agent.Hierarchy == "Employee") {
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getCanceledByAgent";
    }

    let payload = { AgentID: Agent.AgentID, PartnerID: Agent.PartnerID };
    // let payload = { AgentID: "AGT00716", PartnerID: "VTL1295" };

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setData([]);
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const columns = [
    {
      field: "DateSalesOrderClose",
      headerName: "Date Order Closed",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "DateSalesEnter",
      headerName: "Date Entered",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Agent",
      headerName: "Agent ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "CustomerAccountNumber", headerName: "Acc #" },
    {
      field: "CustomerFullName",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "SVCUnitAddressLine1",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "SVCUnitAddressLine2",
      headerName: "Address L2",
      flex: 1,
    },
    {
      field: "SVCUnitZipCode",
      headerName: "Zip",
      flex: 1,
    },
    {
      field: "PSU",
      headerName: "PSU",
      flex: 1,
    },
    {
      field: "FiscalMonth",
      headerName: "Fiscal Month",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header title="Spectrum Canceled Orders" subtitle="Core Reports" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            component={Link}
            to="/agentRegistration"
          >
            <PersonAddAltIcon sx={{ mr: "10px" }} />
            Add Agent
          </Button>
        </Box> */}
      </Box>
      <Box
        m="10px 0 40px 0"
        height="75vh"
        sx={{
          "& .MuiTablePagination-root": {
            color: colors.lightHeaders[100],
          },
          "& .MuiTablePagination-toolbar": {
            color: colors.lightHeaders[100], // Text color in pagination toolbar
          },
          "& .MuiTablePagination-actions .MuiButtonBase-root": {
            color: colors.lightHeaders[100], // Color for the pagination buttons (previous/next)
          },
          "& .MuiTablePagination-actions .MuiSvgIcon-root": {
            color: colors.lightHeaders[100], // Color for the pagination buttons (previous/next)
          },
          "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
            color: colors.lightHeaders[100], // Color for the sort arrows in the column headers
          },
          "& .MuiDataGrid-root": {
            border: "none",
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.lightHeaders[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            color: colors.primary[700],
            padding: "8px",
          },
          "& .MuiButton-root": {
            color: colors.primary[700],
          },
          "& .MuiInputBase-root": {
            color: colors.primary[700], // Text color inside toolbar search input
          },
          "& .MuiSvgIcon-root": {
            color: colors.primary[700], // Icon color inside toolbar
          },
        }}
      >
        <DataGrid
          rows={data.filter((row) => row.AgentID !== "Pending")}
          columns={columns}
          getRowId={(row) => row.ConfirmationNumber}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default Team;
