// src/scenes/TeamExpViewComps/AgentSales.jsx

import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PieChart from "./PieChart"; // Ensure correct path
import BarChart from "./BarChart"; // Ensure correct path
import { tokens } from "../../theme";

const AgentSales = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const providerData = [
    {
      id: 1,
      provider: "Spectrum",
      internet: data.InternetSales,
      video: data.VideoSales,
      voice: data.VoiceSales,
      mobile: 0,
      chargebacks: data.Chargebacks,
      total: data.Units,
    },
    {
      id: 2,
      provider: "DirecTV",
      internet: 0,
      video: 0,
      voice: 0,
      mobile: 0,
      chargebacks: 0,
      total: 0,
    },
    {
      id: 3,
      provider: "Viasat",
      internet: 0,
      video: 0,
      voice: 0,
      mobile: 0,
      chargebacks: 0,
      total: 0,
    },
  ];

  const columns = [
    { field: "provider", headerName: "Provider", flex: 1 },
    { field: "internet", headerName: "Internet", flex: 1 },
    { field: "video", headerName: "Video", flex: 1 },
    { field: "voice", headerName: "Voice", flex: 1 },
    { field: "mobile", headerName: "Mobile", flex: 1 },
    { field: "chargebacks", headerName: "Chargebacks", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
  ];

  const salesData = {
    totalUnitsSold: {
      label: "Total Units Sold",
      value: data.Units || 0,
      breakdown: {
        internet: data.InternetSales || 0,
        video: data.VideoSales || 0,
        voice: data.VoiceSales || 0,
        mobile: data.TotalMobile || 0,
      },
    },
    chargebackSales: {
      label: "Chargeback Sales",
      value: data.Chargebacks || 0,
      breakdown: {
        internet: data.InternetCB || 0,
        video: data.VideoCB || 0,
        voice: data.VoiceCB || 0,
        mobile: 0, // Assuming no data for mobile yet
      },
    },
    cancelledSales: {
      label: "Canceled Sales",
      value: data.SalesCanceled || 0,
      breakdown: {
        internet: data.InternetCanceled || 0,
        video: data.VideoCanceled || 0,
        voice: data.VoiceCanceled || 0,
        mobile: 0,
      },
    },
    pendingSales: {
      label: "Pending Sales",
      value: data.PendingSales || 0,
      breakdown: {
        internet: data.InternetPen || 0,
        video: data.VideoPen || 0,
        voice: data.VoicePen || 0,
        mobile: 0,
      },
    },
    mobileActivations: {
      label: "Mobile Activations",
      value: data.MobileActivations || 0,
      breakdown: {
        onNetwork: data.MobileOnNetwork || 0,
        offNetwork: data.MobileOffNetwork || 0,
      },
    },
    ordersCompleted: {
      label: "Orders Completed",
      value: data.CompletedEntered || 0,
      breakdown: {
        completed: data.CompletedEntered || 0,
        Entered: data.EnteredOrders,
      },
    },
  };

  const dummyData = [
    { id: "Assist", label: "Assist", value: 1 },
    { id: "50/100", label: "50/100", value: 1 },
    { id: "300", label: "300", value: 1 },
    { id: "Ultra", label: "Ultra", value: 1 },
    { id: "Gig", label: "Gig", value: 1 },
  ];

  const InternetData = [
    { id: "Internet Assist", value: data.IntAssist },
    { id: "Internet 100", value: data.Int100 },
    { id: "Internet 300", value: data.Int300 },
    { id: "Internet Ultra", value: data.IntUltra },
    { id: "Internet Gig", value: data.IntGig },
  ];

  const VideoData = [
    { id: "Mi Plan Latino", value: data.VidMPL },
    { id: "Stream Latino", value: data.VidSL },
    { id: "Select Signature", value: data.VidSS },
    { id: "Choice 15", value: data.VidC15 },
    { id: "Stream", value: data.VidStream },
    { id: "Select Plus", value: data.VidSP },
  ];

  const VoiceData = [
    { id: "Units", value: data.Units },
    { id: "Chargebacks", value: data.Chargebacks },
    { id: "Canceled", value: data.SalesCanceled },
  ];

  const internetData = InternetData || dummyData;
  const videoData = VideoData || dummyData;
  const voiceData = VoiceData || dummyData;

  return (
    <Box
      m="10px 0 40px 0"
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      rowGap="15px"
      columnGap="15px"
      justifyContent={"center"}
    >
      {/* Top row with circles */}
      <Box
        sx={{
          gridColumn: "span 12",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {Object.keys(salesData).map((key) => (
          <Box
            key={salesData[key].label}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              fontWeight="600"
              sx={{ marginBottom: "10px" }}
            >
              {salesData[key].label}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginRight: "10px",
                }}
              >
                {Object.keys(salesData[key].breakdown).map((type) => (
                  <Typography key={type} variant="body2">
                    {`${type.charAt(0).toUpperCase() + type.slice(1)}: ${
                      salesData[key].breakdown[type]
                    }`}
                  </Typography>
                ))}
              </Box>
              <Box
                sx={{
                  boxShadow: 3,
                  padding: "10px",
                  backgroundColor: "#f2f0f0",
                  borderRadius: "50%",
                  width: 100,
                  height: 100,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="600"
                  color={colors.blueAccent[500]}
                >
                  {salesData[key].value}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      {/* Provider Breakdown */}
      <Box
        sx={{
          gridColumn: "span 6",
          boxShadow: 3,
          padding: "10px",
          backgroundColor: "#f2f0f0",
        }}
      >
        <Typography variant="h5" fontWeight="600" mb="10px">
          Provider Breakdown
        </Typography>
        <DataGrid
          rows={providerData}
          columns={columns}
          pageSize={3}
          rowsPerPageOptions={[3]}
          disableSelectionOnClick
          autoHeight
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "transparent",
              borderBottom: "none",
              color: colors.grey[900],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "transparent",
              color: colors.grey[100],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        />
      </Box>

      {/* Services Breakdown */}
      <Box
        sx={{
          gridColumn: "span 6",
          boxShadow: 3,
          padding: "10px",
          backgroundColor: "#f2f0f0",
        }}
      >
        <Typography variant="h5" fontWeight="600" mb="10px">
          Services Breakdown
        </Typography>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          height="100%"
        >
          <PieChart data={voiceData} title="Overview" />
          <PieChart data={internetData} title="Internet" />
          <PieChart data={videoData} title="Video" />
        </Box>
      </Box>

      {/* Month over Month Trend */}
      <Box
        sx={{
          gridColumn: "span 12",
          boxShadow: 3,
          padding: "10px",
          backgroundColor: "#f2f0f0",
          height: 500,
        }}
      >
        <Typography variant="h5" fontWeight="600" mb="10px">
          90 Day Week over Week
        </Typography>
        <BarChart data={data} /> {/* Pass the data prop to BarChart */}
      </Box>
    </Box>
  );
};

export default AgentSales;
