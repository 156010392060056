import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const Data = props.data; // Updated to reflect API response structure
  const [completed, setCompleted] = useState(0);
  const [total, setTotal] = useState(0);
  const [colorScale, setColorScale] = useState("#4cceac");

  useEffect(() => {
    if (Agent && Agent.PartnerID && Data) {
      updateOrders(Data);
    }
  }, [Data, Agent]);

  const updateOrders = (data) => {
    const totalOrders = data.TotalOrders || 0;
    const completedOrders = data.CompletedOrders || 0;

    setTotal(totalOrders);
    setCompleted(completedOrders);

    // Update color scale based on completion rate
    const completionRate = (completedOrders / totalOrders) * 100;
    setColorScale(completionRate < 85 ? "#e22839" : "#4cceac");
  };

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Entered Orders
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Entered: {total}</Box>
        <Box>Completed: {completed}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={colorScale} variant="h1" fontWeight="600">
          {total || 0}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
